<template>
    <div>
        <SubHeader :title="'MY PAGE'" :link="'/'"/>
        <div class="content_wrap">
            <div class="flex-center-center flex-column mt-4">
                <img src="@/assets/img/user-icon.svg" alt="profile" width="90" v-if="profile_img == ''">
                <img :src="profile_img" alt="profile" width="90" height="90" class="rounded-circle object-fit-cover" v-else>

                <div class="txt_box w-px-200 mt-2">
                    <ul>
                        <li class="flex-between-center mb-2">
                            <span class="text-burgundy-4">Nickname</span>
                            <span class="fw-bold text-white">{{nickname}}</span>
                        </li>
                        <li class="flex-between-center">
                            <span class="text-burgundy-4">UID No</span>
                            <span class="fw-bold text-white">{{uid}}</span>
                        </li>
                    </ul>
                </div>
                <router-link to="/mypage/edit-profile" class="btn btn-sm text-white border mt-3">
                    Edit Profile
                </router-link>
            </div>

            <div class="mt-4 py-3 border-bottom border-yellow-1">
                <div class="flex text-white mb-3 pb-2 border-bottom border-yellow-1 flex-column">
                    <div class="flex-between-center mb-2">
                        <span>My Wallet</span>
                        <router-link to="/transaction/history" class="flex-bwtween-center small"><span class="fs-px-12">Transaction History</span> <i class="far fa-chevron-right ms-1 small"></i></router-link>
                    </div>
                    
                    <!-- <div class="flex-between-center rounded-1 bg-white ps-2 pe-3 py-px-12 fw-500 mb-2 text-body">
                        <div class="flex-start-center">
                            <span class="ms-2">Referal OCB Point</span>
                        </div>
                        <div class="flex-start-center">
                            <span class="me-1">{{(ocb_point).toLocaleString()}}</span>
                            <div @click="referal_OCB_point_pp=true">
                                <img src="@/assets/img/layout_icon/arrow-alt-right-y.svg" alt="My Referal or Swap OCB" width="28" class="cursor-pointer">
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="flex-between-center rounded-1 bg-white p-3 fw-500 mb-2" v-for="(item,index) in wallet_list" :key="index">
                    <div class="flex-start-center">
                        <img :src="require(`@/assets/img/icon/color/${item.icon}.svg`)" alt="trx" width="28">
                        <span class="ms-2">{{item.name}}</span>
                    </div>
                    <div class="flex-start-center">
                        <p class="word-break-all ps-3 text-end">
                            <span class="d-block me-1">{{(item.balance).toLocaleString()}}</span>
                            <!-- <span class="d-inline-block me-1">(=${{(item.usd).toLocaleString()}})</span> -->
                            <span class="d-inline-block me-1">(=${{ (Math.trunc(item.usd * 100) / 100).toLocaleString() }})</span>
                        </p>
                        <div @click="ClickDetail(item.symbol)">
                            <img src="@/assets/img/layout_icon/exchange.svg" alt="excange" width="28" class="cursor-pointer">
                        </div>
                    </div>
                </div>
                <div class="mt-2 text-center">
                    <span class="text-burgundy-4 fs-px-12">* {{update_time.toLocaleString('en-GB',{ timeZone: 'UTC' })}} retrieved from BINANCE</span>
                </div>
            </div>

            <div class="py-3 border-bottom border-yellow-1">
                <div class="flex-between-center text-white mb-2">
                    <span>My Deals</span>
                </div>
                <ul class="pt-0 d-flex text-center fw-400">
                    <li class="rounded-start py-3 w-50 rounded-bottom-0"
                        :class="{'bg-yellow-1 fw-bold': my_deals_tab==0 , 'bg-yellow-4 text-gray-500' : my_deals_tab !=0}"
                        @click="my_deals_tab = 0"
                    >BUY</li>

                    <li class="rounded-end py-3 w-50 rounded-bottom-0" 
                        :class="{'bg-yellow-1 fw-bold': my_deals_tab==1 , 'bg-yellow-4 text-gray-500' : my_deals_tab !=1}
                        "
                        @click="my_deals_tab = 1"    
                    >SELL</li>
                </ul>
                <div class="rounded-1 bg-white rounded-top-0" v-show="my_deals_tab==0">
                    <ul class="last-border-0">
                        <li class="border-bottom border-yellow-1 mx-3">
                            <router-link to="/mydeal/buy/wish-list" class="flex-between-center py-3">
                                <span>Wishlist</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{wishlist_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </router-link>
                        </li>
                        <li class="border-bottom border-yellow-1 mx-3">
                            <router-link to="/mydeal/buy/participate" class="flex-between-center py-3">
                                <span>Participating Deals</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{participating_deals_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </router-link>
                        </li>
                        <li class="border-bottom border-yellow-1 mx-3">
                            <router-link to="/mydeal/buy/won" class="flex-between-center py-3">
                                <span>Won deals</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{won_deals_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </router-link>
                        </li>
                        <li class="border-bottom border-yellow-1 mx-3">
                            <router-link to="/mydeal/buy/closed" class="flex-between-center py-3">
                                <span>Closed Deals</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{closed_deals_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="rounded-1 bg-white rounded-top-0" v-show="my_deals_tab==1">
                    <ul class="last-border-0">
                        <li class="border-bottom border-yellow-1 mx-3">
                            <router-link to="/mydeal/sell/sale" class="flex-between-center py-3">
                                <span>Deal in progress</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{deal_in_progress_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </router-link>
                        </li>
                        <li class="border-bottom border-yellow-1 mx-3">
                            <router-link to="/mydeal/sell/cancel" class="flex-between-center py-3">
                                <span>Seller Cancelled</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{cancelled_deals_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </router-link>
                        </li>
                        <li class="border-bottom border-yellow-1 mx-3">
                            <router-link to="/mydeal/sell/soldout" class="flex-between-center py-3">
                                <span>Closed deals</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{complete_deals_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="py-3 border-bottom border-yellow-1">
                <router-link to="/notice" class="flex-between-center text-white">
                    <span>Notice & Event</span>
                    <i class="fas fa-chevron-right text-yellow-1 ms-2 me-3"></i>
                </router-link>
            </div>

            <div class="py-3 border-bottom border-yellow-1">
                <router-link to="/mypage/personal-info-setting" class="flex-between-center text-white">
                    <span>Personal Information Setting</span>
                    <i class="fas fa-chevron-right text-yellow-1 ms-2 me-3"></i>
                </router-link>
            </div>

            <div class="py-3 mb-3">
                <router-link to="/mypage/inquiry" class="flex-between-center text-white">
                    <span>Customer Service</span>
                    <i class="fas fa-chevron-right text-yellow-1 ms-2 me-3"></i>
                </router-link>
            </div>
        </div>
        <Footer></Footer>

        <div class="basic_pp center_fix" v-show="referal_OCB_point_pp">
            <div class="dimm bg-body-i bg-opacity-50" @click="referal_OCB_point_pp=false"></div>
            <div class="content bg-body rounded-1">
                <div class="body overflow-auto" ref="basic_pp_body">
                    <div class="text-center">
                        <h6 class="fw-bolder fs-px-15 py-3 pb-4">Referal OCB Point</h6>
                        <router-link :to="`/referral-code`" class="btn thick bg-yellow-1 w-100 flex-center-center mb-3 py-px-12">
                            <small class="ms-2">My Referral</small>
                        </router-link>
                        <router-link :to="`/ocb-swap`" class="btn thick bg-yellow-1 w-100 flex-center-center py-px-12">
                            <small class="ms-2">Swap OCB</small>
                        </router-link>
                        <div class="btn thick flex-center-center mt-3" @click="referal_OCB_point_pp=false">
                            <small class="ms-2">CANCEL</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="basic_pp center_fix" v-show="coin_transaction_pp">
            <div class="dimm bg-body-i bg-opacity-50" @click="coin_transaction_pp=false"></div>
            <div class="content bg-body rounded-1">
                <div class="body overflow-auto" ref="basic_pp_body">
                    <div class="text-center">
                        <h6 class="fw-bolder fs-px-15 py-3 pb-4">{{t_symbol}}</h6>
                        <router-link :to="`/transaction/deposit/${t_symbol}`" class="btn thick bg-yellow-1 w-100 flex-center-center mb-3">
                            <img src="@/assets/img/layout_icon/deposit.svg" width="26">
                            <small class="ms-2">DEPOSIT</small>
                        </router-link>
                        <router-link :to="`/transaction/withdrawal/${t_symbol}`" class="btn thick bg-burgundy-1 w-100 flex-center-center text-white">
                            <img src="@/assets/img/layout_icon/withdrawal.svg" width="26">
                            <small class="ms-2">WITHDRAWAL</small>
                        </router-link>
                        <div class="btn thick flex-center-center mt-3" @click="coin_transaction_pp=false">
                            <small class="ms-2">CANCEL</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        SubHeader, Footer
    },
    data(){
        return{
            nickname: '',
            uid: '',
            profile_img: '',

            wallet_list: [],
            update_time: '',

            wishlist_count: 0,
            participating_deals_count: 0,
            won_deals_count: 0,
            closed_deals_count: 0,

            deal_in_progress_count: 0,
            cancelled_deals_count: 0,
            complete_deals_count: 0,

            t_symbol : '',

            ocb_point: 0,

            // front ui
            coin_transaction_pp: false,
            referal_OCB_point_pp: false,
            my_deals_tab: 0,
        }
    },
    mounted(){
        this.GetMyProfile()
        this.GetWalletInfo()
        this.CheckWalletBalance();
        this.CheckObcBalance();
        this.GetHistoryList();
    },
    methods:{
        ClickDetail(target){
            // if(target !='OCB'){
                this.$pp({
                    msg:'Coming Soon',
                    auto_hide:true
                })
            // }else{
            //     this.t_symbol = target;
            //     this.coin_transaction_pp = true;
            // }
            
            
        },
        CheckWalletBalance(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();



            this.$http.post('/member/mypage/CheckWalletBalance',{req})
        },
        GetHistoryList(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();



            this.$http.post('/member/mypage/GetHistoryList',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            const info = body.info
                            this.wishlist_count = info.wish_count;
                            this.won_deals_count = info.win_count;
                            this.participating_deals_count = info.participate_count;
                            this.closed_deals_count = info.close_count;

                            this.deal_in_progress_count = info.deal_in_progress_count;
                            this.cancelled_deals_count = info.cancelled_deals_count;
                            this.complete_deals_count = info.complete_deals_count;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        CheckObcBalance(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();



            this.$http.post('/member/mypage/CheckOcbBalance',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.ocb_point = body.balance;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        GetWalletInfo(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();



            this.$http.post('/member/mypage/GetWalletInfo',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.wallet_list = body.wallet_list;
                            this.update_time = new Date(body.update_time)
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        GetMyProfile(){
            
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();



            this.$http.post('/member/mypage/GetProfile',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.profile_img = body.info.profile;
                            this.uid = body.info.code;
                            this.nickname = body.info.nick_name;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
    }

}
</script>

<style>

</style>